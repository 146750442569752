.darken-overlay {
  position: relative;
  content: "";

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.darken-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
}
.darken-image {
  filter: brightness(0.5); /* Adjust the brightness value as needed */
}

@media only screen and (max-width: 600px) {
  .single-slide {
    padding-top: 50px; /* Adjust as needed */
  }
  .hero-contents {
    padding-left: 10px; /* Adjust as needed */
    padding-right: 10px; /* Adjust as needed */
  }
}
