.header-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1c2c59;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  z-index: 9999;
}

.whatsapp-icon {
  width: 32px;
  height: 32px;
}
.whatsapp-icon path {
  fill: white !important;
}
.header-whatsapp em {
  display: none; /* Hide the text if you only want the icon */
}
